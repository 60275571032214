import React, { createContext, useContext } from "react";
import { useStaticQuery, graphql } from "gatsby";

export const SEOContext = createContext();

export const SEOProvider = ({ children }) => {
  const {
    wp: { seo },
    site: { siteMetadata: siteMeta },
  } = useStaticQuery(SEOQuery);
  return (
    <SEOContext.Provider value={{ seo, siteMeta }}>
      {children}
    </SEOContext.Provider>
  );
};

export const useSeoContext = () => useContext(SEOContext);

export default SEOContext;

const SEOQuery = graphql`
  query getGlobalSEO {
    wp {
      seo {
        contentTypes {
          post {
            title
            schemaType
            metaRobotsNoindex
            metaDesc
          }
          page {
            metaDesc
            metaRobotsNoindex
            schemaType
            title
          }
        }
        webmaster {
          googleVerify
          yandexVerify
          msVerify
          baiduVerify
        }
        schema {
          companyName
          personName
          companyOrPerson
          wordpressSiteName
          siteUrl
          siteName
          inLanguage
          logo {
            sourceUrl
            mediaItemUrl
            altText
          }
        }
        social {
          facebook {
            url
            defaultImage {
              sourceUrl
              mediaItemUrl
            }
          }
          instagram {
            url
          }
          linkedIn {
            url
          }
          mySpace {
            url
          }
          pinterest {
            url
            metaTag
          }
          twitter {
            username
          }
          wikipedia {
            url
          }
          youTube {
            url
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
